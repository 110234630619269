import {
  assertInInjectionContext,
  EnvironmentProviders,
  inject,
  InjectionToken,
  makeEnvironmentProviders,
  Provider,
} from '@angular/core';

export const environment = getConfig();

export function injectLgsConfiguration(): LgsConfiguration {
  assertInInjectionContext(injectLgsConfiguration);
  return inject(APPLICATION_CONFIGURATION);
}

export function provideLgsConfiguration(): EnvironmentProviders {
  const providers: Provider[] = [
    { provide: APPLICATION_CONFIGURATION, useFactory: getLgsConfiguration },
  ];

  return makeEnvironmentProviders(providers);
}

type LgsFeatures = Record<'USE_NEW_CERTIFICATE' | 'USE_NEW_STUDENT_CARD', boolean>;

function getConfig(): {
  apiURL: string;
  auth0: { DOMAIN: string; CLIENT_ID: string; AUDIENCE: string };
  university: 'undj' | 'uvt' | 'enstp';
  features: LgsFeatures;
} {
  switch (window.location.host) {
    case 'app.universite-ndjamena.td':
    case 'lgs.universite-ndjamena.td':
      return {
        apiURL: 'https://undj.welma-education.com/api',
        auth0: {
          DOMAIN: 'universite-ndjamena.eu.auth0.com',
          CLIENT_ID: 'AewJdKGzFYxnZfd5cBy8ySoff0oX9JWU',
          AUDIENCE: 'https://api.universite-ndjamena.td',
        },
        university: 'undj',
        features: {
          USE_NEW_CERTIFICATE: false,
          USE_NEW_STUDENT_CARD: false,
        },
      };
    case 'lgs.universite-virtuelle.td':
      return {
        apiURL: 'https://uvt.welma-education.app/api',
        auth0: {
          DOMAIN: 'universite-virtuelle.eu.auth0.com',
          CLIENT_ID: 'CNx6RUN7bumrScAQgiDx6gR1eVXG24Ge',
          AUDIENCE: 'https://api.universite-virtuelle.td',
        },
        university: 'uvt',
        features: {
          USE_NEW_CERTIFICATE: true,
          USE_NEW_STUDENT_CARD: true,
        },
      };
    case 'lgs.welma.app':
    case 'www.welma.app':
      return {
        apiURL: 'https://staging.welma-education.app/api',
        auth0: {
          DOMAIN: 'welma-education-staging.eu.auth0.com',
          CLIENT_ID: 'XSHAc8sUutfdXeTkC0u30MtjUxnqfpwk',
          AUDIENCE: 'https://api.welma.app',
        },
        university: 'undj',
        features: {
          USE_NEW_CERTIFICATE: true,
          USE_NEW_STUDENT_CARD: true,
        },
      };
    case 'enstp.lgs.education.welma.app':
    case 'lgs.enstp.td':
      return {
        apiURL: 'https://enstp.welma-education.app/api',
        auth0: {
          DOMAIN: 'enstp.eu.auth0.com',
          CLIENT_ID: 'NPDtlN2JEvLCcyJwqXJcdDIpnMqTO9aJ',
          AUDIENCE: 'https://enstp.api.education.welma.app',
        },
        university: 'enstp',
        features: {
          USE_NEW_CERTIFICATE: true,
          USE_NEW_STUDENT_CARD: true,
        },
      };
    default:
      return {
        apiURL: '/api',
        auth0: {
          DOMAIN: 'welma-education-staging.eu.auth0.com',
          CLIENT_ID: 'XSHAc8sUutfdXeTkC0u30MtjUxnqfpwk',
          AUDIENCE: 'https://api.welma.app',
        },
        university: 'uvt',
        features: {
          USE_NEW_CERTIFICATE: true,
          USE_NEW_STUDENT_CARD: true,
        },
      };
  }
}

const APPLICATION_CONFIGURATION = new InjectionToken<LgsConfiguration>('APPLICATION_CONFIGURATION');

interface LgsConfiguration {
  university: {
    name: string;
    logo: string;
    website: string;
    address: string;
    city: string;
    director: {
      title: string;
      name: string;
      signature: string;
    };
  };
  theme: {
    primary: string;
    accent: string;
    hideName: boolean;
  };
  features: LgsFeatures;
}

const configs = {
  uvt: {
    university: {
      name: `Université Virtuelle du Tchad`,
      logo: 'https://res.cloudinary.com/doknvxtth/image/upload/v1703085470/logo-universite-virtuelle_i4ulxx.png',
      website: 'www.uvt.td',
      address: 'BP : 5711 N’Djaména - Tchad',
      city: `N'Djaména`,
      director: {
        title: 'Le Vice-Président chargé des enseignements',
        name: 'Dr BATOUMA NARKOY',
        signature: '',
      },
    },
    theme: {
      primary: '#1A78F4',
      accent: '#FBC700',
      hideName: true,
    },
  },
  undj: {
    university: {
      name: `Université de N'Djaména`,
      logo: 'https://res.cloudinary.com/doknvxtth/image/upload/logo-ndjamena_z9zede.png',
      website: 'www.universite-ndjamena.td',
      address: 'B.P: 1117 / Tél. (235) 22 51 54 54',
      city: `N'Djaména`,
      director: {
        title: 'Directeur de la Scolarité et des Examens',
        name: 'Pr. Amir Moungache',
        signature:
          'https://res.cloudinary.com/doknvxtth/image/upload/v1703114755/signature-directeur-undj_t8jlr9.png',
      },
    },
    theme: {
      primary: '#002569',
      accent: '#FFCE00',
      hideName: false,
    },
  },
  enstp: {
    university: {
      name: `ENSTP`,
      logo: 'https://res.cloudinary.com/doknvxtth/image/upload/v1704708383/logo-enstp-square_y7e5f1.png',
      website: 'www.enstp.td',
      address: 'B.P: 1117 / Tél. (235) 22 51 54 54',
      city: `N'Djaména`,
      director: {
        title: 'Directeur des Études',
        name: 'HINAMARI SAVAISSEL',
        signature: '',
      },
    },
    theme: {
      primary: '#2A3084',
      accent: '#EC752E',
      hideName: false,
    },
  },
} as const satisfies Record<string, Omit<LgsConfiguration, 'features'>>;

function getLgsConfiguration(): LgsConfiguration {
  const config = configs[environment.university];
  return {
    university: config.university,
    theme: config.theme,
    features: environment.features,
  };
}
